<template>
    <div class="content-wrapper">
        <PageHeader screenName="Departamentos regionais" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="departamento"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="changeStateDepartamento"
            @canceled="cancelDelete"
        />

        <TimedAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <main class="card hidden_overflow_tabela">
            <div class="pt-2 px-2">
                <b-button @click="redirectToRegister()" variant="primary-button">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"   
                    />
                    <span class="align-middle">Adicionar novo Departamento</span>
                </b-button>
            </div>
            <hr>
            <TableFilter
                :filter="filter"
                @defaultSizeChanged="setFirstPage"
                @filterTable="loadDataDepartamento"
            />
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="list-departamento" v-if="!loadingData">
                <b-table
                    id="tableDepartamento"
                    responsive
                    :stickyColumn="true"
                    :busy.sync="filtering"
                    :fields="table.fields"
                    :items="table.items"
                    @sort-changed="ordenarColuna"
                    no-local-sorting
                    :no-sort-reset="true"
                >
                    <template #cell(descricao)="row">
                        <span class="font-weight-bold">{{ row.item.descricao }}</span>
                    </template>
                    <template #cell(uf)="row">
                        <span class="font-weight-bold">{{ row.item.uf}} - {{ row.item.estado }}</span>
                    </template>
                    <template #cell(ativo)="row">
                        <TableSwitch 
                            :checked="row.item.ativo" 
                            :description="row.item.descricao"
                            :itemId="row.item.id_departamento"
                            @stateModified="openModalConfirm"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <feather-icon
                            @click.prevent="redirectToView(row.item)"
                            id="view-departamento"
                            role="button"
                            class="text-custom-blue mr-1"
                            icon="EyeIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Detalhes do Departamento</b>"
                        />
                        <feather-icon
                            @click.prevent="redirectToEdit(row.item)"
                            id="edit-departamento"
                            role="button"
                            class="text-custom-blue"
                            icon="EditIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Editar Departamento</b>"
                        />
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="withoutDepartamento" class="ml-1">Nenhum departamento regional encontrado</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                    </b-col>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';

import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';

export default {
    title: 'Departamentos',

    components: {
        BForm, BCol, BRow, BFormGroup, BFormInput, 
        BButton, BTable, TableSwitch,
        vSelect, CustomPagination, PageHeader, 
        ConfirmModal, TimedAlert, TableFilter
    },

    data() {
        return {
            alert: {
                errorMessage: '',
                show: false
            },
            linkItems: [
                {
                    name: 'Departamentos regionais',
                    active: true
                }
            ],
            filtering: false,
            filter: false,
            loadingData: true,
            withoutDepartamento: false, 
            table: {
                fields: [
                    {key: "descricao", label: "departamento regional", sortable: true, class: 'mw-400'},
                    {key: "uf", label: "UF - estado", sortable: true, class: 'mw-300'},
                    {key: "ativo", label: "Status", sortable: true, class: 'mw-50'},
                    {key: "actions", label: "Ações", class: 'mw-150 text-center col-lg-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'descricao',
                order: 'asc'

            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            deleteItem: null,
            modal: {
                showModal: false,
                typeModal: 'success',
            }
        }
    },

    methods: {
        setFirstPage() {
            this.updateCurrentPage(1);
        },

        loadDataDepartamento(filterData = null) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            if(filterData.hasOwnProperty('currentPage')) { this.paginationData.currentPage = filterData.currentPage; }

            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order
            };

            this.filtering = true;

            this.$http.get(this.$api.departamento(), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;
                this.withoutDepartamento = false;

                if(data.data.length == 0) {
                    this.withoutDepartamento = true;
                }

                this.loadingData = false;

                this.filtering = false;
                this.startPagination(data)
            })
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;
            this.filter = !this.filter;
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;

            this.filtering = true;
            this.filter = !this.filter;
        },

        redirectToEdit({ id_departamento, sigla }) {
            this.$router.push(
                { 
                    name: 'departamento-edit', 
                    params: {
                        idDepartamento: id_departamento, 
                        acronymDepartamento: sigla
                    }
                }
            );
        },

        redirectToView({ id_departamento, sigla }) {
            this.$router.push(
                { 
                    name: 'departamento-view', 
                    params: {
                        idDepartamento: id_departamento, 
                        acronymDepartamento: sigla
                    }
                }
            );
        },

        redirectToRegister() {
            this.$router.push({name: 'departamento-register'});
        },

        openModalConfirm(values) {
            this.modal.typeModal = 'warning';

            if(values.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, values, 'id_departamento');

            this.deleteItem = values;
            this.modal.showModal = true;
        },

        changeStateDepartamento() {
            this.$http.patch(this.$api.departamentoParameter(this.deleteItem.itemId)).then(() => {
                this.modal.showModal = false;
            }).catch(({ response: { data } }) => {
                this.modal.showModal = false;

                this.$helpers.backStateActiveTableRow(this.table.items, this.deleteItem, 'id_departamento');

                this.alert.errorMessage = data.errors;
                this.alert.show = !this.alert.show;
            });

        },

        cancelDelete() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.deleteItem, 'id_departamento');

            this.modal.showModal = false;
        }
    }
}
</script>

<style>
.hidden_overflow_tabela{
    overflow: hidden;
}
</style>